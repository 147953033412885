import Project from "./SingleProject"

const Projects = () => {

  const projects = [
    {
      key: 0,
      img: "lebenswirksamkeit.jpeg",
      title: "Eltern stärken Eltern",
      text: "Psychoedukative Selbsthilfegruppe mit dem Ziel Schulverweigerer erneut in die Schule einzuführen.",
      link: 'https://lebenswirksamkeit-eg.at'
    },
    {
      key: 1,
      img: "fabo_img.jpg",
      title: "Familie als Bonus",
      text: "FABO - Familie als Bonus ist ein Elternbildungsangebot für alle werdenden Eltern und Eltern von Kindern von 0-4 Jahren.",
      link: 'https://www.familiealsbonus.at/'
    },
    {
      key: 2,
      img: "jeki_bus.png",
      title: "Jugend Eltern Kind Info",
      text: "Jeki informiert direkt vor Ort über regionale Angebote im Bezirk Hartberg-Fürstenfeld.",
      link: '/jeki'
    },
    {
      key: 3,
      img: "move_your_body.png",
      title: "Move Your Body",
      text: "Erlebe Freude an der Bewegung und entdecke spannende neue Sportarten. Gemeinsam bleiben wir aktiv und fit!",
      link: '/move_your_body'
    },
    {
      key: 4,
      img: "hot_pot.jpg",
      title: "Hot-Pot.KOMM",
      text: "Küche und Garten als offener Kommunikationsraum für Gemeinsames Kochen, Genießen und Erleben von Garten und Natur.",
      link: '/hot_pot'
    },
    {
      key: 5,
      img: "stabil.jpg",
      title: "STABIL",
      text: "STABIL bietet Jugendlichen von 14 bis 21 Jahren einen Ort für soziale Kontakte, Freundschaften und persönliche Entwicklung sowie die Möglichkeit, praktische Fähigkeiten zu stärken.",
      link: '/stabil'
    },
  ]

  return(
    <div className="w-full pt-0 md:pt-8 lg:pt-12 xl:pt-16 flex flex-col gap-8 lg:gap-16 xl:gap-20 xl:px-8">
      {projects.map((project, index) => {
        return <Project project={project} isLeft={index%2===0} key={index}/>
      })}
    </div>
  )
}

export default Projects