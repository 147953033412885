import ProjectInfo from "../components/project_details/ProjectHeader"
import PageContainer from "./PageContainer"
import Selbstvertrauen from '../assets/web/selbstvertrauen5.png'
import Persönlichkeit from '../assets/web/persönlichkeit2.png'
import Kompetenz from '../assets/web/kompetenz.png' 
import Ideen from '../assets/web/ideen.png' 



import Info from "../components/project_details/Info"
import { useEffect } from "react"

const StabilPage = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
      
    const project = {
        headline:'STABIL',
        info: 'Das Projekt ,,Stabil“ zielt darauf ab, Jugendliche im Alter von 14 bis 21 Jahren einen Ort für soziale Kontakte, Freundschaften, Selbstverwirklichung und den Ausbau von praktischen Kompetenzen zu bieten.',
        image: 'stabil.jpg'
    }

    return(
        <PageContainer>
            <div className="flex flex-col gap-8 md:gap-8 lg:gap-12 xl:gap-24">
            <ProjectInfo project={project}/>
            <div className="flex flex-col w-full gap-10 items-center md:items-start">
                <div className="text-2xl lg:text-3xl xl:text-5xl font-semibold pt-6">Worum geht's?</div>
                <div className="flex flex-col md:grid md:grid-cols-2 md:grid-rows-1 gap-x-8 xl:gap-y-24 md:gap-y-12 gap-y-8">
                    <Info text={<span><span className="font-semibold">Stärkung</span> der <span className="font-semibold">Selbstverantwortung</span> der Teilnehmer:innen.</span>} icon={Selbstvertrauen}/>
                    <Info text={<span><span className="font-semibold">Förderung</span> der <span className="font-semibold">Persönlichkeitsentwicklung</span>.</span>} icon={Persönlichkeit}/>
                    <Info text={<span>Ausbau <span className="font-semibold">praktischer Kompetenzen</span>.</span>} icon={Kompetenz}/>
                    <Info text={<span><span className="font-semibold">Eigene Ideen</span> und <span className="font-semibold">Vorstellungen</span> verwirklichen.</span>} icon={Ideen}/>
                </div>
                <div className="flex flex-col w-full text-center md:text-left md:w-3/5 gap-4 pt-8 md:pt-10">
                    <div className="text-2xl lg:text-3xl xl:text-5xl font-semibold">Wo und wann findest du uns?</div>
                    <div className="text-md lg:text-lg xl:text-2xl"><span>Einmal im Monat, donnerstags in der ARGE und bei Bedarf außerhalb.</span>
                    <br /><br/>
                    <span>
                        Die Termine werden im Vorfeld in der ARGE ausgehängt und in die WhatsApp-Gruppe, in der die Teilnehmer:innen die Möglichkeit haben sich auszutauschen, hineingestellt.
                    </span>
                    </div>
                    <div/>
                    <div className="flex flex-col py-4 md:py-8">
                        <div className="text-2xl lg:text-3xl xl:text-5xl font-semibold">Anmeldung:</div>
                        <div className="w-full flex md:flex-row flex-col text-md lg:text-lg xl:text-2xl gap-8 md:gap-24 py-4 md:py-8">
                            <div className="flex flex-col gap-2">
                                <div>Katrin Wiedner</div>
                                <div>0676/898678706</div>
                                <div className="text-nowrap">k.wiedner@tg-steiermark.at</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div>Tanja Schirnhofer</div>
                                <div>0664/88239656</div>
                                <div>tanja.schirnhofer@sdhf.at</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>

        </PageContainer>
    )
}

export default StabilPage